<template>
  <b-row no-gutters class="mb-7 flex-nowrap">
    <b-col
      role="button"
      cols="10"
      @click="$emit('view-milestone', milestone.id)"
    >
      <b-row
        no-gutters
        align-v="center"
        class="bg-white text-color-2 font-weight-medium pl-5 pr-4 py-1"
      >
        <b-col cols="1" class="mr-6">
          <inline-svg
            :src="
              require(`@/assets/icons/${
                milestoneTypes[milestone.type].icon
              }.svg`)
            "
            class="py-2"
            fill="currentColor"
            height="60"
            width="70"
          ></inline-svg>
        </b-col>
        <b-col class="pt-1 font-size-12">
          {{ milestone.description }}
        </b-col>
        <b-col
          v-if="profile.type === 'mentor'"
          cols="auto"
          class="align-self-start pl-3"
        >
          <MentoringPlanMilestoneDropdown
            :milestone="milestone"
            @view-milestone="$emit('view-milestone', $event)"
            @update-milestone="$emit('update-milestone', $event)"
            @edit-milestone="$emit('edit-milestone', $event)"
          />
        </b-col>
      </b-row>
      <b-row
        no-gutters
        align-h="between"
        class="bg-primary text-white border-radius-top-0 border-radius-10 px-3 py-4"
      >
        <b-col
          v-for="dataItem in infoData"
          :key="dataItem.name"
          class="py-2 py-lg-0 px-sm-2"
          cols="12"
          lg="auto"
          md="4"
          sm="auto"
        >
          <b-row align-v="center" class="flex-nowrap px-8 px-sm-0" no-gutters>
            <b-col class="mr-2" cols="auto">
              <inline-svg
                :src="require(`@/assets/icons/${dataItem.icon}.svg`)"
                height="18"
                width="18"
                stroke="#FFFFFF"
              ></inline-svg>
            </b-col>
            <b-col class="font-size-12 mr-2" cols="auto">
              {{ dataItem.label }}:
            </b-col>
            <b-col class="font-weight-medium" cols="auto">
              {{ dataItem.value }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
    <b-col
      class="d-flex flex-column pl-5"
      :class="profile.type === 'mentor' ? 'pr-3' : 'pr-8'"
    >
      <div
        class="d-flex justify-content-center align-items-center bg-white py-3 col"
      >
        <inline-svg
          :src="
            require(`@/assets/icons/${
              milestoneStatuses[milestone.status].icon
            }.svg`)
          "
          height="40"
          width="40"
        ></inline-svg>
      </div>
      <div
        class="text-white border-radius-top-0 text-center border-radius-10 px-1 py-4"
        :style="{ backgroundColor: milestoneStatuses[milestone.status].color }"
      >
        {{ milestoneStatuses[milestone.status].label }}
      </div>
    </b-col>
    <b-col
      v-if="profile.type === 'mentor'"
      cols="auto"
      class="d-flex align-items-center"
    >
      <b-button
        variant="transparent"
        :class="{ 'milestone-drag': !isConcluded }"
      >
        <inline-svg
          :src="require(`@/assets/icons/hamburger-menu-more.svg`)"
          height="32"
          width="32"
        ></inline-svg>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import MentoringPlanMilestoneDropdown from "@/components/MentoringPlan/MentoringPlanMilestoneDropdown";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "MentoringPlanMilestone",
  components: { MentoringPlanMilestoneDropdown },
  props: {
    milestone: {
      type: Object
    },
    milestoneTypes: {
      type: Object
    },
    milestoneStatuses: {
      type: Object
    },
    milestoneStep: {
      type: Number
    },
    isConcluded: {
      type: Boolean
    }
  },
  data() {
    return {
      infoData: [
        {
          name: "start_date",
          label: this.$t("mentroingprogram.infoDataStarted"),
          icon: "calendar",
          value: null
        },
        {
          name: "duration",
          label: this.$t("mentroingprogram.infoDataDuration"),
          icon: "time-circle",
          value: null
        },
        {
          name: "type",
          label: this.$t("mentroingprogram.infoDataType"),
          icon: "type",
          value: null
        },
        {
          name: "no_of_milestones",
          label: this.$t("mentroingprogram.infoDataMilestone"),
          icon: "milestone",
          value: this.milestone.index
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  },
  created() {
    for (const key of Object.keys(this.milestone)) {
      const index = this.infoData.findIndex(i => i.name === key);
      if (index > -1) {
        if (key === "start_date") {
          this.infoData[index].value = moment(this.milestone[key]).format(
            "Do MMMM yyyy"
          );
        } else if (key === "type") {
          this.infoData[index].value = this.milestoneTypes[
            this.milestone[key]
          ].label;
        } else if (key == "duration") {
          this.infoData[index].value =
            this.milestone[key] + " " + this.milestone.duration_type;
        } else {
          this.infoData[index].value = this.milestone[key];
        }
      }
    }
  }
});
</script>
