
import Vue from "vue";

export default Vue.extend({
  name: "MentoringPlanMilestoneDropdown",
  props: {
    milestone: {
      type: Object
    }
  },
  data() {
    return {};
  },
  methods: {}
});
